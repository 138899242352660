<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <!--  :src="barImage" -->
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item class="px-0">
        <v-list-item-avatar
          class="align-self-center mx-0"
          color="white"
          contain
          size="45"
        >
          <v-img
            src="@/assets/yassir-symbol.png"
            max-height="40"
            max-width="27"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
          <v-list-item-title
            v-if="user.store_name"
            class="subtitle-2"
            v-text="user.store_name"
          />
          <v-list-item-title
            v-if="user.hub_name"
            class="subtitle-4"
            v-text="user.hub_name"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>

    <v-divider class="mb-1" />

    <template v-slot:append>
      <v-list
        expand
        nav
      >
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon v-text="`mdi-power-standby`" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t('logout')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [
        {
          icon: "mdi-view-dashboard",
          title: "dashboard",
          name: "dashboard",
          to: "/dashboard",
        },

        {
          icon: "mdi-table-cog",
          title: "setup.title",
          name: "setup",
          group: "setup",
          expanded: true,
          to: "/setup/",
          children: [
            ...(this.$admin.hasAccessTo("brand")
              ? [
                  {
                    title: "brands",
                    name: "brands",
                    to: "/setup/brands",
                  },
                ]
              : []),

            // ... this.$admin.hasAccessTo('attribute') ?
            // [
            //     {
            //         title: 'attributes',
            //         name: 'attributes',
            //         to: '/setup/attributes',
            //     },
            // ] : [],

            ...(this.$admin.hasAccessTo("hub")
              ? [
                  {
                    title: "hubs",
                    name: "hubs",
                    to: "/setup/hubs",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("product")
              ? [
                  {
                    title: "products.products",
                    name: "products.products",
                    to: "/setup/products",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("product")
              ? [
                  {
                    icon: "mdi-account-lock",
                    title: "setup.grouped_products.title",
                    name: "packs-and-bundles",
                    to: "/setup/packs-and-bundles",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("provider")
              ? [
                  {
                    title: "providers",
                    name: "providers",
                    to: "/setup/providers",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("section")
              ? [
                  {
                    title: "sections",
                    name: "sections",
                    to: "/setup/sections",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("alert")
              ? [
                  {
                    title: "alerts",
                    name: "alerts",
                    to: "/setup/alerts",
                  },
                ]
              : []),

            ...(this.$admin.can("sync-manual-action")
              ? [
                  {
                    title: "SyncProducts",
                    name: "SyncProducts",
                    to: "/setup/sync/products",
                  },
                ]
              : []),
          ],
        },

        ...(this.$admin.hasAccessTo("category")
          ? [
              {
                icon: "mdi-bookshelf",
                title: "shelving",
                name: "shelving",
                group: "shelving",
                expanded: true,
                to: "/shelving/",
                children: [
                  {
                    title: "shelves",
                    name: "shelves",
                    to: "/shelving/shelves",
                  },
                  {
                    title: "categories",
                    name: "categories",
                    to: "/shelving/categories",
                  },
                  {
                    title: "special-categories",
                    name: "special-categories",
                    to: "/shelving/special-categories",
                  },
                ],
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("stockManagement")
          ? [
              {
                icon: "mdi-store-clock-outline",
                title: "stockManagements.stock-managements",
                name: "stock-managements",
                to: "/stock-managements",
              },
            ]
          : []),
        ...(this.$admin.hasAccessTo("product")
          ? [
              {
                icon: "mdi-package-variant",
                title: "products_by_hub",
                name: "ProductsByHub",
                to: "/products-by-hub",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("supplyOrder")
          ? [
              {
                icon: "mdi-cog-transfer",
                title: "supply_orders",
                name: "supply-orders",
                to: "/supply-orders",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("stockAdjustment")
          ? [
              {
                icon: "mdi-cog-transfer",
                title: "stockAdjustments.stock-adjustments",
                name: "stock-adjustments",
                to: "/stock-adjustments",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("inventory")
          ? [
              {
                icon: "mdi-cart-variant",
                title: "inventories",
                name: "inventories",
                group: "inventories",
                expanded: true,
                to: "/inventories/",
                children: [
                  ...(this.$admin.hasAccessTo("inventory")
                    ? [
                        {
                          title: "inventory.index",
                          name: "inventories.index",
                          to: "/inventories/index",
                        },
                      ]
                    : []),

                  ...(this.$admin.can("inventory-create")
                    ? [
                        {
                          title: "create",
                          name: "inventories.create",
                          to: "/inventories/create",
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("inbound")
          ? [
              {
                icon: "mdi-transfer",
                title: "inbound_products",
                name: "inbound-products",
                to: "/inbound-products",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("transfer")
          ? [
              {
                icon: "mdi-swap-horizontal",
                title: "transfers",
                name: "transfers",
                to: "/transfers",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("order")
          ? [
              {
                icon: "mdi-cart",
                title: "orders",
                name: "orders",
                group: "orders",
                expanded: true,
                to: "/orders/",
                children: [
                  {
                    title: "all",
                    name: "orders.all",
                    to: "/orders/all",
                  },
                  ...(this.$admin.hasAccessTo("log")
                    ? [
                        {
                          title: "sync_log",
                          name: "log.orders.sync",
                          to: "/orders/log/sync",
                        },
                        {
                          title: "unsynced_log",
                          name: "log.orders.unsynced",
                          to: "/orders/log/unsynced",
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("promotion")
          ? [
              {
                icon: "mdi-sale",
                title: "promotions.title",
                name: "promotions.index",
                to: "/promotions",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("coupon")
          ? [
              {
                icon: "mdi-ticket-percent",
                title: "coupons.title",
                name: "coupons.index",
                to: "/coupons",
              },
            ]
          : []),

        ...(this.$admin.can("order-pos") || this.$admin.hasAccessTo("checkout")
          ? [
              {
                icon: "mdi-cart-variant",
                title: "pos-new",
                name: "pos",
                group: "pos",
                expanded: true,
                to: "/pos-new/",
                children: [
                  ...(this.$admin.can("order-pos")
                    ? [
                        {
                          title: "create",
                          name: "pos-new.create",
                          to: "/pos-new/create",
                        },
                        {
                          title: "local-orders",
                          name: "pos.local-orders",
                          to: "/pos-new/local-orders",
                        },
                        {
                          title: "sync-orders-history",
                          name: "pos.sync_order_history",
                          to: "/pos-new/history",
                        },
                      ]
                    : []),
                  ...(this.$admin.hasAccessTo("checkout")
                    ? [
                        {
                          title: "checkouts",
                          name: "ops.checkouts",
                          to: "/pos-new/checkouts",
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("accounting")
          ? [
              {
                icon: "mdi-finance",
                title: "accounting",
                name: "accounting",
                to: "/accounting",
              },
            ]
          : []),
        ...(this.$admin.hasAccessTo("statistic")
          ? [
              {
                icon: "mdi-chart-areaspline",
                title: "statistics",
                name: "statistics",
                to: "/statistics",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("user")
          ? [
              {
                icon: "mdi-account-supervisor",
                title: "users",
                name: "users",
                to: "/users",
              },
            ]
          : []),

        ...(this.$admin.hasAccessTo("role")
          ? [
              {
                icon: "mdi-account-lock",
                title: "roles",
                name: "roles",
                to: "/roles",
              },
            ]
          : []),

        {
          icon: "mdi-account",
          title: "profile",
          name: "profile",
          to: "/#profile",
        },

        {
          icon: "mdi-cog",
          title: "settings",
          name: "settings",
          group: "settings",
          expanded: true,
          to: "/settings/",
          children: [
            {
              title: "change_password",
              name: "change-password",
              to: "/settings/change-password",
            },

            ...(this.$admin.hasAccessTo("setting-supplyOrder")
              ? [
                  {
                    title: "settings.procurement-settings",
                    name: "procurement-settings",
                    to: "/settings/procurement-settings",
                  },
                ]
              : []),
            ...(this.$admin.can("setting-price")
              ? [
                  {
                    title: "prices",
                    name: "prices",
                    to: "/settings/price",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("setting-specialCategory")
              ? [
                  {
                    title: "settings.category-settings",
                    name: "category-settings",
                    to: "/settings/category-settings",
                  },
                ]
              : []),
          ],
        },
      ],
    };
  },

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },

    user() {
      return this.$store.getters.getUser;
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },

    logout() {
      this.$store.dispatch("logout");
      this.$store.dispatch("checkAuth");
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
